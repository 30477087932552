/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
//import LogRocket from 'logrocket'
require('./src/styles/global.less')
require('./src/styles/content.less')
const LogRocket = require('logrocket')

exports.onClientEntry = () => {
  LogRocket.init('wupram/homey-learn')
}
